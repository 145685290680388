.con-Login{
    width: 100%;
    height: 100vh;
    background-color: #f8f8f8;
}

.purple-square-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading-page{
    display: flex;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  
  .purple-square {
    background-color: #ffffff;
    width: 500px;
    height: auto;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
  }

  .img-logo{
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }

  .logo-sty{
    width: 70px;
    height: 70px;
  }

  #components-form-demo-normal-login .login-form {
    max-width: 300px;
  }
  #components-form-demo-normal-login .login-form-forgot {
    float: right;
  }
  #components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
    float: left;
  }
  #components-form-demo-normal-login .login-form-button {
    width: 100%;
  }

  .con-form{
      padding: 20px;
  }

  .ant-input {
    /* background-color: #FFFFFF !important; */
  
  }

  .center-btn{
    display: flex;
    justify-content: center;
  }

  .btn-login{
    height: 40px;
    width: 100%;
    color: #fff;
    background: #356989;
    border-color: #7d9db3;
    font-size: 18px;
  }

  /* .ant-btn-primary {
    color: #fff;
    background: #356989;
    border-color: #7d9db3;
    font-size: 18px;
  } */
.user-list{
  background-color: #ffffff;
  height: 100vh;
  border-right-style: solid;
  border-right-width: thin;
  border-right-color: #e5e5e5;
  width: 430px;
  cursor: pointer;
  
}

@media only screen and (max-width: 800px) {
  .user-list {
    width:100%;
  }
}

.chat-list{
  background-color: #ffffff;
  height: 100vh;
}

.noti-list{
  border-left-style: solid;
  border-left-width: thin;
  border-left-color: #e5e5e5;
  background-color: #ffffff;
  height: 100vh;
}

.header-st{
  width: 100%;
  height: 60px;
  background:linear-gradient(90deg, rgba(16,72,152,1) 0%, rgba(40,105,189,1) 100%);;
  position: absolute;
  z-index: 90;
  top: 0;
}

.footer-chat {
  height: 11vh !important;
  border-top: 1px solid #e6e6e6;
  padding: 15px;
  display: flex;
  /* position: absolute; */
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
}

.footer-chat-nodata{
  height: 11vh !important;
  padding: 15px;
  display: flex;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
}

.ant-layout-footer {
  padding: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: #ffffff;
}


.input-chat{
  height: 38px;
  border-radius: 5px;
  border: 1px solid #ced4da !important;
  margin-right: 25px;
  margin-right: 15px;
}
.chat{
  position: relative;
  /* height: 200px; */
  /* border: 3px solid red; */
}

.chat-left{
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  margin-right: 50px;
  border-radius: 7px;
  flex-direction: column;
  background-color: #ebebeb;
  margin-bottom: 10px;
}

.chat-right{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  margin-left: 50px;
  border-radius: 7px;
  color: #ffffff;
  background-color: #0099ff;
  margin-bottom: 15px;
}

.flex-start-chat{
  display: flex;
  justify-content:flex-start
}

.flex-end-chat{
  display: flex;
  justify-content: flex-end;
}

.txt-text{
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

.txt-time-left{
  opacity: 0.8;
  font-size: 11px;
  font-family: 'Inter', sans-serif;
  color: #757575;
}

.txt-time-right{
  opacity: 0.8;
  text-align: right;
  font-size: 11px;
  font-family: 'Inter', sans-serif;
  color: #757575;
}

.ab-chat{
  width: 100%;
  bottom: 10px;
  margin-right: 15px;
  padding: 3px 25px 1px 25px;
}

.chat-bottom{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  /* height: 72vh; */
}

.bell-icon:hover{
  background-color: #f3f3f3;
  width: 40px;
  height: 40px;
  border-radius: 130px;
  display: flex;
  justify-content: center;
}

.bell-icon{
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 130px;
  display: flex;
  justify-content: center;
}
.demo-infinite-container {
  overflow: auto;
  outline: none;
  height: 87vh;
  color: #ffffff;
  margin-top: 15px;
}

.chat-infinite-container{
  overflow: auto;
  outline: none;
  height: 79vh;
  padding: 15px;
  color: #ffffff;
}

.center-btn-send{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.w-top-chat{
  padding-bottom: 80px  !important;;
}
.logo-top-chat{
  padding: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.btn-two{
  display: flex;
}

.btn-outline:hover{
    color: #dcdcdc;
    border-width: 1px;
    border-style: solid;
    color: #212529;
    /* width: 50px; */
    padding: 10px;
    height: 40px;
    justify-content: center;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.icon-chat-sider{
  padding: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.sider-chatleft-active{
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 7px;
}
.div-sider-chat{
  padding: 15px;
  /* background-color: #f9f9f9; */
  border-radius: 7px;
}

.sider-left{
  border-right-style: solid !important;
  border-right-width: thin;
  border-right-color: #ebebeb;
}

.card-user{
  /* width: 100%;
  height: 100px;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-color: #ebebeb;
  border-bottom-width: thin;
  display: flex;
  justify-content: center;
  padding: 17px 30px;
  flex-direction: column; */
  width: 95%;
  height: 80px;
  margin: 10px;
  background-color: #ffffff;
  display: flex;
  border-radius: 15px;
  justify-content: center;
  padding: 17px 30px;
  flex-direction: column;
}

.loading-frist{
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 17px 30px;
  flex-direction: column;
}

.card-user:hover{
  background-color: #c5c5c512;
}

.card-user-active {
  width: 95%;
  margin: 10px;
  height: 80px;
  display: flex;
  border-radius: 15px;
  justify-content: center;
  padding: 17px 30px;
  flex-direction: column;
  background-color: #a29f9f12;
  /* width: 100%;
  height: 100px;
  border-bottom-style: solid;
  border-bottom-color: #ebebeb;
  border-bottom-width: thin;
  display: flex;
  justify-content: center;
  padding: 17px 30px;
  flex-direction: column;
  background-color: #9c939312; */
}

.card-Notification-active{
  /* width: 100%;
  height: 100px;
  border-bottom-style: solid;
  border-bottom-color: #ebebeb;
  border-bottom-width: thin;
  display: flex;
  justify-content: center;
  padding: 17px 30px;
  flex-direction: column;
  background-color: #a29f9f12; */
  width: 95%;
  margin: 10px;
  height: 80px;
  display: flex;
  border-radius: 15px;
  justify-content: center;
  padding: 17px 30px;
  flex-direction: column;
  background-color: #a29f9f12;
}

.subtitle-txt{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
  color: #969696;
  font-family: 'Inter',sans-serif;
  /* width:200px; */
}
.dot-txt{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
    font-family: 'Inter',sans-serif;
    font-weight: normal;
    color: rgb(77, 81, 86);
}

.flex-dot-txt{
  display: flex;
  flex-direction: row;
}



.img-col{
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80px;
}

.p-col{
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80px;
  width: 200px;
}


.loading-chat{
    background-color: rgb(255, 255, 255);
    width: 100%;
    align-items: center;
    display: grid;
    margin-top: 10px;
}

.btn-outline{
    border-color: #e6e6e6;
    color: #dcdcdc;
    border-width: 1px;
    border-style: solid;
    color: #212529;
    /* width: 50px; */
    padding: 10px;
    height: 40px;
    justify-content: center;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.arrow-left{
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
}

.flex-header-noti{
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
}

.ant-badge-not-a-wrapper {
  float: right !important;
  vertical-align: middle;
}

.Badge-col{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0px 0px 0px;

}

.img-top{
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 50px;
}

.mL{
  margin-left: 15px;
}

p{
  margin-top: 0;
  margin-bottom: 0px;
}

.user-top{
  width: 100%;
  height: 50px;
  position: absolute;
  background-color: #ffffff;
  box-shadow: -1px 1px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.btn-swicth{
  display: flex;
  justify-content: flex-end;
  height: 40px;
  right: 20px;
  align-items: center;
  margin-right: 15px;
}

.disable-chat{
  width: 100%;
  height: 100px;
  background-color: #7d7b7b;
  position: absolute;
  opacity: 0.5;
  z-index: 1;
}

.header-siderLeft{
  width: 100%;
  height: 105px;
}


.con-input{
  /* padding-left: 30px;
  padding-right: 30px; */
  margin-top: 15px;
  margin-bottom: 15px;
}

.txt-Chat{
  font-size: 20px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: #4d5156;
}

.input-search{
  /* background-color: #ffffff !important;
  border-radius: 5px;
  font-size: 17px;
  border-style: solid;
  height: 36px;
  text-align: left;
  border-style: solid;
  width: 90%;
  margin-left: 22px; */
  background-color: #f9f8f8 !important;
  border: 0px solid #d9d9d9 !important; 
  border-radius: 5px;
  font-size: 17px;
  height: 36px;
  text-align: left;
  width: 95%;
  margin-left: 9px;
  border-radius: 20px;
}

.txt-header{
  color: #ffffff;
  font-size: 28px;
}

.center-txt-haeder{
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 60px;
  margin-left: 15px;
}

.swict-row{
  width: 100%;
  height: 40px;
  background-color: #7d7b7b;
  z-index: 5;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}

.center-icon{
  display: flex;
  align-items: center;
  margin-left: 15px;
  height: 40px;
}
.btn-sty, .btn-sty:hover{
  background-color: #7d7b7b;
  border-style: solid;
  border-color: #ffffff;
  border-width: thin;
  color: #ffffff;
  cursor: pointer;
    padding: 4px;
}

.cricle-noti{
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #000000;
}
.header-user{
  padding-top: 10px;
  z-index: 5;
  background-color: #ffffff;
  border-right-width: thin;
  width: 100%;
  border-right-color: #ebebeb;
  height: 11vh;
}

.flex-header{
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
}

.card-Notification{
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 20px;
}

.txt-error{
  display: flex;
}


#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-background {
  background: #fff;
}

h5{
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.users-list-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}


.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    border-top: 0;
    background-color: inherit;
    border-color: #ebebeb;
}

.layout .content .chat .chat-header{
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
}

.flex-userActive{
  display:flex;
}

.center-header{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.p-time-chat{
  text-align: right;
  font-size: 12px;
  padding-top: 7px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

.user-id-chat{
  font-family: 'Inter', sans-serif;
  color: #1e1e1f;
}

.p-time-noti{
  text-align: right;
  font-size: 12px;
  padding-top: 7px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

.center-p{
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #000000;
}

.center-Switch{
  display: flex;
  justify-content: center;
  flex-direction: row;
  color: #000000;
}

.header-id{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1;
  font-family: 'Inter', sans-serif;
  color: #4d5156;
}

.Empty-Chat{
  height: 70vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.site-layout-sub-header-background {
  background: #fff;
  height: 10vh;
  border-bottom-style: solid;
  border-bottom-color: #ebebeb;
  padding: 10px 30px;
  justify-content: space-between;
  display: flex;
  border-bottom-width: thin;
  /* position: absolute; */
  width: 100%;
  z-index: 2;
}
.site-layout-background{
  background: #fff;
  height: 10vh;
  width: 100%;
  z-index: 2;
}

.send-img{
  width: 50px;
  height: 38px;
  display: flex;
  align-items: center;
  background-color: #0099ff;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 5px;
  border: 1px solid transparent;
}

*::-webkit-scrollbar-thumb {        
  box-shadow: inset 0 0 0 11px;
}

.demo-infinite-container:hover, .chat-infinite-container:hover {
  color: rgba(0, 0, 0, 0.3);
}


.select-admin{
  
}